import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import {
  addDatabaseModalAtom,
  allDatabasesAtom,
  curConversationAtom,
  curDatabaseAtom,
  featureAtom,
  featureExpandAtom,
  selectedFilesAtom,
} from '../../../../recoil/atoms/atoms';
import { FeatureOption } from '../../navigation';
import Swal from 'sweetalert2';
import { FileUploader } from '../../datasources/components/file_uploader';
import {
  getAllDatabases,
  uploadDataset,
} from '../../../../apis/datasources/apis';
import { useEffect, useState } from 'react';
import {
  ConversationType,
  CreateConversationDto,
} from '../../../../apis/conversations/interfaces';
import { startNewConversation } from '../../../../apis/conversations/apis';
import {
  DatasetType,
  UploadDataset,
} from '../../../../apis/datasources/interfaces';
import { fetchConversationWithId } from '../../../../utils/conversation_utils';

export const DataEnty = () => {
  const [curDatabase, setCurDatabase] = useRecoilState(curDatabaseAtom);
  const setAddDatabaseModal = useSetRecoilState(addDatabaseModalAtom);
  const setOption = useSetRecoilState(featureAtom);
  const selectedFiles = useRecoilValue(selectedFilesAtom);
  const [isSelectingDatabase, setIsSelectingDatabase] = useState(false);
  const [allDatabases, setAllDatabases] = useRecoilState(allDatabasesAtom);
  const setCurConversation = useSetRecoilState(curConversationAtom);

  const setExpand = useSetRecoilState(featureExpandAtom);
  const setFeatureOption = useSetRecoilState(featureAtom);

  useEffect(() => {
    getAllDatabases().then(setAllDatabases);
    setExpand(true);
  }, []);

  const renderAllDatabases = () => {
    return allDatabases.map((database) => {
      return (
        <div
          key={database.id}
          className="h-8 w-full pl-4 flex items-center hover:bg-layer2"
          onClick={() => {
            setIsSelectingDatabase(false);
            setCurDatabase(database);
          }}
        >
          <span className="text-sm">{database.name}</span>
        </div>
      );
    });
  };

  const handleStartConversation = async () => {
    // if (!curDatabase) {
    //   Swal.fire('Error', "Document can't be empty", 'error');
    //   return;
    // }

    if (selectedFiles.length === 0) {
      Swal.fire('Error', "File can't be empty", 'error');
      return;
    }
    // Upload a single file
    const dataset: UploadDataset = {
      files: [selectedFiles[0]], // Wrap selectedFiles[0] inside an array
      type: DatasetType.S3File,
    };

    uploadDataset(dataset).then((res) => {
      const createConversationDto: CreateConversationDto = {
        conversationName: res[0].name,
        conversationType: ConversationType.DATAENTRY,
        dataentry: {
          databaseId: curDatabase?.id ?? null,
          datasetIds: [res[0].id],
        },
      };

      startNewConversation(createConversationDto)
        .then(convId => {
          fetchConversationWithId(
            convId,
            3,
            300,
            (conv) => {
              conv.connectedDataset = res[0];
              setCurConversation(conv);
              setFeatureOption(FeatureOption.NONE);
              setExpand(false);
            },
            (err) => {
              Swal.fire('Error', JSON.stringify(err), 'error');
            }
          );
        })
        .catch(err => {
          Swal.fire('Error', JSON.stringify(err.data));
        });
    }).catch((err) => {
      Swal.fire('Error', JSON.stringify(err));
    });
    // startNewConversation(createConversationDto)
    //   .then((convId) => {
    //     // add a small pause to avoid race condition
    //     setTimeout(() => {}, 300);
    //     return getConversation(convId);
    //   })
    //   .then((conv) => {
    //     const connectedDataset = {};
    //     setCurConversation(conv);
    //     setFeatureOption(FeatureOption.NONE);
    //     setExpand(false);
    //   })
    //   .catch((err) => {
    //     Swal.fire('Error', JSON.stringify(err.data));
    //   });
  };

  return (
    <div className="flex-1 flex m-auto h-full w-[712px] text-text_unselected">
      <div className="flex flex-col h-auto w-full self-center gap-3">
        <div className="flex items-center gap-3 my-6">
          <svg
            width="36"
            height="38"
            viewBox="0 0 36 38"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.29104 28.8986H12.2027V18.8478H8.29104V28.8986ZM23.7973 28.8986H27.709V8.79707H23.7973V28.8986ZM16.0442 28.8986H19.9558V22.8681H16.0442V28.8986ZM16.0442 18.8478H19.9558V14.8275H16.0442V18.8478ZM4.44955 37.3478C3.21794 37.3478 2.16844 36.9021 1.30106 36.0106C0.433688 35.1192 0 34.0405 0 32.7747V4.92099C0 3.65516 0.433688 2.57651 1.30106 1.68504C2.16844 0.793575 3.21794 0.347839 4.44955 0.347839H31.5504C32.7821 0.347839 33.8316 0.793575 34.6989 1.68504C35.5663 2.57651 36 3.65516 36 4.92099V32.7747C36 34.0405 35.5663 35.1192 34.6989 36.0106C33.8316 36.9021 32.7821 37.3478 31.5504 37.3478H4.44955Z"
              fill="#DE82FF"
            />
          </svg>
          <div className="flex flex-col gap-1">
            <span className="text-2xl font-bold text-fuchsia-400">
              Data Entry
            </span>
            <span className="text-text_unselected">
              Generate various query and data based on your dataset
            </span>
          </div>
        </div>

        <div className="relative">
          {allDatabases.length === 0 ? (
            <div
              className="flex gap-1 items-center justify-center h-10 w-44 bg-active rounded cursor-pointer"
              onClick={() => {
                setAddDatabaseModal(true);
              }}
            >
              <div className="w-6 h-6 bg-blueplus" />
              <span className=" text-text_selected text-sm text-semibold">
                Add New Database
              </span>
            </div>
          ) : (
            <>
              <span className="pl-1 font-medium text-sm">Choose Database</span>
              <div
                className="my-2 h-12 rounded border-solid border-outline_unselected border bg-transparent pl-4 text-sm font-normal outline-none text-text_unselected flex items-center cursor-pointer"
                onClick={() => setIsSelectingDatabase(!isSelectingDatabase)}
              >
                <span className={`${curDatabase && 'text-text_unselected'}`}>
                  {curDatabase ? curDatabase.name : 'Select a Database'}
                </span>
              </div>
              {isSelectingDatabase && (
                <div className="absolute mt-0 w-full h-auto z-10 max-h-36 bg-active_hover rounded">
                  <div className="py-2 max-h-36 overflow-auto">
                    {renderAllDatabases()}
                  </div>
                </div>
              )}
            </>
          )}
          <FileUploader />
          <div className={'mt-2 h-10 w-full flex justify-end'}>
            <div className="flex items-center">
              <div
                className="ml-6 w-20 h-full flex items-center justify-center border border-outline_unselected rounded cursor-pointer"
                onClick={() => setOption(FeatureOption.NONE)}
              >
                <span className="font-semibold">Close</span>
              </div>
              <div
                className="ml-6 w-40 h-full flex items-center justify-center border border-outline_unselected rounded bg-layer2 cursor-pointer"
                onClick={handleStartConversation}
              >
                <span className="text-text_unselected font-semibold">
                  Start Conversation
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
