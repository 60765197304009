import { useEffect, useState } from 'react';
import { inviteUser } from '../../../apis/auth/apis';
import { InviteDto } from '../../../apis/auth/interfaces';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { authAtom, subscriptionInfoAtom, teamAccessModalAtom, userMetaAtom } from '../../../recoil/atoms/atoms';
import { getLatestSubscription } from '../../../apis/billing/apis';
import { ModalContainer } from '../../components/modal_container';

export const TeamAccessModal = () => {
  const [inviteeEmail, setInviteeEmail] = useState('');
  const [finish, setFinish] = useState(false);
  const [inviteId, setInviteId] = useState('');
  const authInfo = useRecoilValue(authAtom);
  const setTeamAccessModal = useSetRecoilState(teamAccessModalAtom);
  const [subscriptionInfo, setSubscriptionInfo] =
    useRecoilState(subscriptionInfoAtom);
  const [userMeta, ] = useRecoilState(userMetaAtom);
  const [deletingInvitee, setDeletingInvitee] = useState<string>();

  useEffect(() => {
    getLatestSubscription().then(setSubscriptionInfo);
  }, []);

  const handleInvite = () => {
    const inviteInfo: InviteDto = { email: inviteeEmail };
    inviteUser(inviteInfo).then((inviteStatus) => {
      setInviteeEmail('');

      if (inviteStatus && inviteStatus.success) {
        setFinish(true);
        setInviteId(inviteStatus.invite_id);
      }
    });
  };

  if (!authInfo) return null;

  return (
    <>
      <ModalContainer>
        {finish ? (
          <div className="my-10 w-full flex flex-col items-center justify-center text-center">
            <div className="w-12 h-12 bg-bluecheck" />
            <span className="mt-6 text-xl font-bold">
              Invitee Added Successful
            </span>
            <span className="mt-4 text-sm font-normal text-text_unselected">
              Copy the invite id
              <p className="text-text_selected font-semibold my-2"> {inviteId} </p>
              and the invitee can use it when sign up
            </span>
            <div
              className="mt-8 w-32 h-10 bg-white rounded flex items-center justify-center cursor-pointer"
              onClick={() => setTeamAccessModal(false)}
            >
              <span className="text-black font-semibold text-sm">Finish</span>
            </div>
          </div>
        ) : (
          <div className="h-full mx-10 my-10">
            {subscriptionInfo && userMeta && (
              <>
                <div className="relative w-full flex flex-col justify-center gap-2">
                  <div
                    className="absolute top-0 right-0 w-6 h-6 bg-close cursor-pointer scale-150"
                    onClick={() => setTeamAccessModal(false)}
                  />
                  <p className="text-xl font-bold">Team Access</p>
                  <p>{authInfo.username}</p>
                  <div className="text-text_unselected">
                    <p className="leading-normal">
                      <span className="text-gright">{`The ${subscriptionInfo.subscription_level} plan `}</span>
                      allows a maximum of
                      <span className="text-gright">{` ${subscriptionInfo.sizeOfTeam} `}</span>{' '}
                      member(s).
                    </p>
                    <p>
                      Shared with{' '}
                      <span className="text-gright">{` ${userMeta.nonAdminUsers.length} `}</span>
                      member(s).
                    </p>
                  </div>
                </div>

                <div className="flex flex-col mt-10 h-auto">
                  <span className="font-semibold text-sm">
                    Invite Team Members
                  </span>
                  <div className="mt-2 flex w-full items-center h-10 gap-2">
                    <input
                      className="h-full flex-grow rounded pl-4 bg-layer1"
                      placeholder="Enter email address"
                      value={inviteeEmail}
                      onChange={(event) =>
                        setInviteeEmail(
                          (event.target as HTMLInputElement).value
                        )
                      }
                    />
                    <div
                      className="w-28 h-full flex items-center justify-center border border-active_hover rounded bg-white cursor-pointer"
                      onClick={handleInvite}
                    >
                      <span className="text-black font-semibold">Share</span>
                    </div>
                  </div>

                  <div className="flex flex-col mt-3 justify-center">
                    {userMeta.nonAdminUsers.length > 0 &&
                      userMeta.nonAdminUsers.map((user, index) => (
                        <div
                          key={index}
                          className="flex items-center justify-between p-2 hover:bg-layer2"
                        >
                          {user.email}
                          <div
                            className="w-6 h-6 bg-close cursor-pointer"
                            onClick={() => setDeletingInvitee(user.email)}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </ModalContainer>
      <>
        {deletingInvitee && (
          <ModalContainer>
            <div className="h-full mx-10 my-10">
              <div className="w-full flex flex-col justify-center gap-4">
                <p className="text-xl font-bold">Delete Account</p>
                <p>
                  Are you sure you want to delete access for
                  <span className="text-gright">{` ${deletingInvitee}`}</span>?
                  This member will no longer be able to access the account once
                  you delete it .
                </p>
              </div>
              <div className="mt-10 h-10 w-full flex justify-end">
                <div className="flex items-center">
                  <span
                    className="w-28 h-full flex items-center justify-center border border-active_hover rounded cursor-pointer text-sm text-text_unselected"
                    onClick={() => setDeletingInvitee(undefined)}
                  >
                    Cancel
                  </span>
                  <div className="ml-6 w-28 h-full flex items-center justify-center border border-active_hover rounded bg-white cursor-pointer">
                    <span className="text-black font-semibold">Delete</span>
                  </div>
                </div>
              </div>
            </div>
          </ModalContainer>
        )}
      </>
    </>
  );
};
